import React, { useContext } from "react"
import { GlobalContext } from "../context/provider.js"
import { Button } from "react-bootstrap"
import LoanComparisonModal from "./loanComparisonModal"
import "./loanComparisonBar.css"

const LoanComparisonBar = () => {
  const { state, dispatch } = useContext(GlobalContext)

  const maxLoans = 3
  const { compareLoans } = state

  return (
    <div className="loan-comparison-bar bg-primary">
      <div className="loan-comparison-bar-container">
        <Button
          onClick={() =>
            dispatch({ type: "TOGGLE_LOAN_COMPARISON_MODAL", show: true })
          }
          className="loan-comparison-button"
          variant="primary"
        >
          Compare {compareLoans.length}/{maxLoans} loans
        </Button>
        <Button
          onClick={() => dispatch({ type: "CLEAR_LOAN_COMPARISON" })}
          className="loan-comparison-clear"
        >
          Clear
        </Button>
      </div>
      <LoanComparisonModal />
    </div>
  )
}

export default LoanComparisonBar
