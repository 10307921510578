import React, { useContext, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import { GlobalContext } from "../context/provider.js"
import UserSavings from "./userSavings"
import InputError from "./inputError"
import Search from "../assets/search-white.svg"
import TooltipIcon from "../assets/tooltip.svg"
import ArrowUp from "../assets/arrow-up-white.svg"
import ArrowDown from "../assets/arrow-down-white.svg"
import {
  Container,
  Button,
  InputGroup,
  FormControl,
  Tooltip,
  OverlayTrigger,
  Alert,
} from "react-bootstrap"
import "./filterInput.css"
import { numberFormatter, isNumberValidForUpdating } from "./../utils/helpers"

const renderPITooltip = props => (
  <Tooltip {...props}>Principal & Interest</Tooltip>
)

const renderIOTooltip = props => <Tooltip {...props}>Interest Only</Tooltip>
const renderSMSFTooltip = props => (
  <Tooltip {...props}>Self Managed Super Fund Loans</Tooltip>
)

const FilterInput = ({ lowestRate, loading, error, productQuantity }) => {
  const { state, dispatch } = useContext(GlobalContext)

  const loanAmountInput = useRef(null)
  const propertyValueInput = useRef(null)
  const interestRateInput = useRef(null)
  const loanTermInput = useRef(null)
  // const ongoingFeeInput = useRef(null)

  const handleLoanAmountChange = useCallback(() => {
    const loanAmount = loanAmountInput.current.value.replaceAll(",", "")

    isNumberValidForUpdating(loanAmount) &&
      dispatch({
        type: "SET_LOAN_AMOUNT",
        loanAmount: loanAmount,
      })
  }, [dispatch])

  const handlePropertyValueChange = useCallback(() => {
    const propertyValue = propertyValueInput.current.value.replaceAll(",", "")

    isNumberValidForUpdating(propertyValue) &&
      dispatch({
        type: "SET_PROPERTY_VALUE",
        propertyValue: propertyValue,
      })
  }, [dispatch])

  const handleLoanTermChange = useCallback(() => {
    const loanTerm = loanTermInput.current.value
    isNumberValidForUpdating(loanTerm) &&
      dispatch({
        type: "SET_LOAN_TERM",
        loanTerm: loanTerm,
      })
  }, [dispatch])

  const handleInterestRateChange = useCallback(() => {
    const interestValue = interestRateInput.current.value

    isNumberValidForUpdating(interestValue) &&
      dispatch({
        type: "SET_INTEREST_RATE",
        interestRate: interestValue,
      })
  }, [dispatch])

  // const handleOngoingFeeChange = useCallback(() => {
  //   dispatch({
  //     type: "SET_ONGOING_FEE",
  //     ongoingFee: ongoingFeeInput.current.value,
  //   })
  // }, [dispatch])

  // const handleFeeFrequencyChange = useCallback(
  //   e => {
  //     dispatch({ type: "SET_FEE_FREQUENCY", feeFrequency: e })
  //   },
  //   [dispatch]
  // )

  const userLoan = {
    loanAmount: parseFloat(state.loanAmount),
    interestRate: parseFloat(state.interestRate),
    loanTerm: parseInt(state.loanTerm),
    isInterestOnly: state.interestPaymentType === "IO",
  }

  return (
    <>
      <Container fluid className="filter-container">
        {state.showFilter && (
          <div>
            <div className="filter-row-center">
              <div className="filter-col">
                <Button
                  onClick={() =>
                    dispatch({ type: "SET_REFINANCE", refinance: true })
                  }
                  className={`filter-button-refinance filter-button filter-left-button left-icon-holder ${
                    state.refinance
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  {" "}
                  I want to refinance
                </Button>
                <Button
                  onClick={() =>
                    dispatch({ type: "SET_REFINANCE", refinance: false })
                  }
                  className={`filter-button-refinance filter-button filter-right-button right-icon-holder ${
                    state.refinance
                      ? "filter-button-inactive"
                      : "filter-button-active"
                  }`}
                >
                  I need a loan
                </Button>
              </div>
            </div>
            <div className="filter-row">
              <div className="filter-col">
                <Button
                  onClick={() =>
                    dispatch({ type: "SET_PURPOSE", purpose: "OO" })
                  }
                  className={`filter-button-purpose filter-button filter-left-button ${
                    state.purpose === "OO"
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  To Live In
                </Button>
                <Button
                  onClick={() =>
                    dispatch({ type: "SET_PURPOSE", purpose: "INV" })
                  }
                  className={`filter-button-purpose filter-button filter-right-button ${
                    state.purpose === "INV"
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  To Invest
                </Button>
                <Button
                  onClick={() =>
                    dispatch({ type: "SET_PURPOSE", purpose: "SMSF" })
                  }
                  className={`filter-button-purpose filter-button filter-right-button ${
                    state.purpose === "SMSF"
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  SMSF{" "}
                  <OverlayTrigger placement="top" overlay={renderSMSFTooltip}>
                    <span className="tooltip-icon">
                      <TooltipIcon />
                    </span>
                  </OverlayTrigger>
                </Button>
              </div>
              <div className="filter-col">
                <Button
                  onClick={() =>
                    dispatch({ type: "SET_INTEREST_TYPE", interestType: "ALL" })
                  }
                  className={`filter-button-interest-type filter-button filter-left-button ${
                    state.interestType === "ALL"
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  All
                </Button>
                <Button
                  onClick={() =>
                    dispatch({
                      type: "SET_INTEREST_TYPE",
                      interestType: "FIXED",
                    })
                  }
                  className={`filter-button-interest-type filter-button filter-middle-button ${
                    state.interestType === "FIXED"
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  Fixed
                </Button>
                <Button
                  onClick={() =>
                    dispatch({
                      type: "SET_INTEREST_TYPE",
                      interestType: "VARIABLE",
                    })
                  }
                  className={`filter-button-interest-type filter-button filter-right-button ${
                    state.interestType === "VARIABLE"
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  Variable
                </Button>
              </div>
              <div className="filter-col">
                <Button
                  onClick={() =>
                    dispatch({
                      type: "SET_INTEREST_PAYMENT_TYPE",
                      interestPaymentType: "ALL",
                    })
                  }
                  className={`filter-button-interest-only filter-button filter-left-button right-icon-holder ${
                    state.interestPaymentType === "ALL"
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  All
                </Button>
                <Button
                  onClick={() =>
                    dispatch({
                      type: "SET_INTEREST_PAYMENT_TYPE",
                      interestPaymentType: "PI",
                    })
                  }
                  className={`filter-button-interest-only filter-button filter-left-button right-icon-holder ${
                    state.interestPaymentType === "PI"
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  P&I{" "}
                  <OverlayTrigger placement="top" overlay={renderPITooltip}>
                    <span className="tooltip-icon">
                      <TooltipIcon />
                    </span>
                  </OverlayTrigger>
                </Button>
                <Button
                  onClick={() =>
                    dispatch({
                      type: "SET_INTEREST_PAYMENT_TYPE",
                      interestPaymentType: "IO",
                    })
                  }
                  className={`filter-button-interest-only filter-button filter-right-button right-icon-holder ${
                    state.interestPaymentType === "IO"
                      ? "filter-button-active"
                      : "filter-button-inactive"
                  }`}
                >
                  IO{" "}
                  <OverlayTrigger placement="top" overlay={renderIOTooltip}>
                    <span className="tooltip-icon">
                      <TooltipIcon />
                    </span>
                  </OverlayTrigger>
                </Button>
              </div>
            </div>
            <div
              className={state.refinance ? "filter-row" : "filter-row-collapse"}
            >
              <div className="filter-col">
                <div className="filter-input-label text-primary">
                  My loan amount
                </div>
                <InputGroup className="filter-input-group">
                  <InputGroup.Prepend className="filter-input-unit-left">
                    <InputGroup.Text
                      className="filter-input-unit-text"
                      id="loan-amount"
                    >
                      $
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className="filter-input-right"
                    placeholder="Enter loan amount"
                    aria-label="loanAmount"
                    aria-describedby="loan-amount"
                    ref={loanAmountInput}
                    value={
                      parseFloat(state.loanAmount) > 0
                        ? numberFormatter(state.loanAmount)
                        : ""
                    }
                    onChange={handleLoanAmountChange}
                  />
                </InputGroup>
              </div>
              <div className="filter-col">
                <div className="filter-input-label text-primary">
                  My property value
                </div>
                <InputGroup className="filter-input-group">
                  <InputGroup.Prepend className="filter-input-unit-left">
                    <InputGroup.Text
                      className="filter-input-unit-text"
                      id="property-value"
                    >
                      $
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className="filter-input-right"
                    placeholder="Enter property value"
                    aria-label="propertyValue"
                    aria-describedby="property-value"
                    ref={propertyValueInput}
                    value={
                      parseFloat(state.propertyValue) > 0
                        ? numberFormatter(state.propertyValue)
                        : ""
                    }
                    onChange={handlePropertyValueChange}
                  />
                </InputGroup>
              </div>
              <div className="filter-col">
                <div className="filter-input-label text-primary">
                  My loan term
                </div>
                <InputGroup className="filter-input-group">
                  <FormControl
                    className="filter-input-left form-select"
                    as="select"
                    aria-label="loanTerm"
                    aria-describedby="loan-term"
                    ref={loanTermInput}
                    value={state.loanTerm}
                    onChange={handleLoanTermChange}
                  >
                    {Array(30)
                      .fill()
                      .map((_, i) => i + 1)
                      .map(v => (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      ))}
                  </FormControl>
                  <InputGroup.Append className="filter-input-unit-right">
                    <InputGroup.Text
                      className="filter-input-unit-text"
                      id="loan-term"
                    >
                      years
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
              {state.refinance && (
                <div className="filter-col">
                  <div className="filter-input-label text-primary">
                    My current rate
                  </div>
                  <InputGroup className="filter-input-group">
                    <FormControl
                      className="filter-input-left"
                      placeholder="Enter rate"
                      aria-label="interestRate"
                      aria-describedby="interest-rate"
                      ref={interestRateInput}
                      value={
                        parseFloat(state.interestRate) > 0
                          ? state.interestRate
                          : ""
                      }
                      onChange={handleInterestRateChange}
                    />
                    <InputGroup.Append className="filter-input-unit-right">
                      <InputGroup.Text
                        className="filter-input-unit-text"
                        id="interest-rate"
                      >
                        %
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              )}
              {/* {state.refinance && (
                <div className="filter-col">
                  <div className="filter-input-label text-primary">
                    My current fees
                  </div>
                  <InputGroup className="filter-input-group">
                    <InputGroup.Prepend className="filter-input-unit-left">
                      <InputGroup.Text
                        className="filter-input-unit-text"
                        id="ongoing-fee"
                      >
                        $
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      className="filter-input-middle"
                      placeholder="Enter fees"
                      aria-label="ongoingFee"
                      aria-describedby="ongoing-fee"
                      ref={ongoingFeeInput}
                      value={state.ongoingFee > 0 ? state.ongoingFee : ""}
                      onChange={handleOngoingFeeChange}
                    />
                    <DropdownButton
                      title={state.feeFrequency === "Annual" ? "p.a." : "p.m."}
                      className="filter-input-unit-right"
                      onSelect={handleFeeFrequencyChange}
                    >
                      <Dropdown.Item
                        className="filter-input-dropdown-item"
                        eventKey="Annual"
                      >
                        p.a.
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="filter-input-dropdown-item"
                        eventKey="Monthly"
                      >
                        p.m.
                      </Dropdown.Item>
                    </DropdownButton>
                  </InputGroup>
                </div>
              )} */}
            </div>
            {state.refinance &&
              parseFloat(state.loanAmount) > 0 &&
              parseFloat(state.propertyValue) > 0 &&
              lowestRate >= 0 &&
              parseFloat(state.interestRate) > 0 &&
              parseFloat(state.propertyValue) >
                parseFloat(state.loanAmount) && (
                <div className="filter-row-center">
                  <div className="filter-col">
                    <UserSavings userLoan={userLoan} rate={lowestRate} />
                  </div>
                </div>
              )}
            {parseFloat(state.loanAmount) > 0 &&
              parseFloat(state.propertyValue) > 0 &&
              parseFloat(state.propertyValue) <=
                parseFloat(state.loanAmount) && (
                <div className="filter-row-center">
                  <div className="filter-col">
                    <InputError
                      header="Almost There"
                      message="Just enter a property value greater than the loan amount"
                    />
                  </div>
                </div>
              )}
            {parseFloat(state.loanAmount) > 0 &&
              parseFloat(state.propertyValue) > 0 &&
              parseFloat(state.propertyValue) > parseFloat(state.loanAmount) &&
              state.refinance &&
              !state.interestRate && (
                <div className="filter-row-center">
                  <div className="filter-col">
                    <InputError
                      header="Almost There"
                      message="Just enter your current interest rate"
                    />
                  </div>
                </div>
              )}
            {((state.refinance &&
              parseFloat(state.loanAmount) > 0 &&
              parseFloat(state.propertyValue) > 0 &&
              parseFloat(state.propertyValue) > parseFloat(state.loanAmount) &&
              parseFloat(state.interestRate) > 0) ||
              (!state.refinance &&
                parseFloat(state.loanAmount) > 0 &&
                parseFloat(state.propertyValue) > 0 &&
                parseFloat(state.propertyValue) >
                  parseFloat(state.loanAmount))) &&
              !state.showProducts &&
              (!loading ? (
                !error ? (
                  <div className="filter-row-center">
                    <div className="filter-col">
                      <Button
                        onClick={() => dispatch({ type: "SHOW_PRODUCTS" })}
                        variant="primary"
                        className="filter-submit-button left-icon-holder"
                      >
                        <span className="filter-icon-search">
                          <Search />
                        </span>
                        View {productQuantity} loans
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Alert
                    variant="danger"
                    style={{
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    Error in fetching products.
                  </Alert>
                )
              ) : (
                <p style={{ textAlign: "center" }}>...Loading</p>
              ))}
          </div>
        )}
        <div className="filter-row-last">
          <div className="filter-col">
            {state.showProducts && (
              <Button
                variant="secondary"
                className="filter-toggle-button left-icon-holder"
                onClick={() => dispatch({ type: "TOGGLE_FILTER" })}
              >
                <span className="filter-toggle-arrow">
                  {state.showFilter ? <ArrowUp /> : <ArrowDown />}
                </span>{" "}
                {state.showFilter ? "Close Filter" : "Show Filter"}
              </Button>
            )}
          </div>
        </div>
      </Container>
      {state.showProducts && <hr className="filter-divider-line" />}
    </>
  )
}

FilterInput.propTypes = {
  lowestRate: PropTypes.number.isRequired,
}

export default FilterInput
