import React, { useContext } from "react"
import PropTypes from "prop-types"
import { GlobalContext } from "../context/provider.js"
import { Container, Row, Col } from "react-bootstrap"
import { calculateRepayment, REPAYMENT_TYPE_PI_IO } from "../utils/helpers"
import "./loanDetails.css"

const mapAnswer = {
  Y: "Yes",
  N: "No",
}

const mapIntType = {
  FIXED: "Fixed",
  VARIABLE: "Variable",
}

const LoanDetails = ({ product }) => {
  const { state } = useContext(GlobalContext)

  const isInterestOnly = product.RepaymentType === "IO"

  const userMonthlyRepayment = calculateRepayment(
    parseFloat(state.loanAmount),
    parseFloat(state.interestRate),
    parseInt(state.loanTerm) * 12,
    isInterestOnly
  )
  const monthlyRepayment = calculateRepayment(
    parseFloat(state.loanAmount),
    parseFloat(product.IntRate),
    parseInt(state.loanTerm) * 12,
    isInterestOnly
  )

  const monthlySavings = Math.round(userMonthlyRepayment - monthlyRepayment)
  const rateSavings =
    Math.round((state.interestRate - parseFloat(product.IntRate)) * 100) / 100

  return (
    <div className="loan-details">
      <Container className="loan-details-box">
        <Row>
          <Col className="loan-details-header text-primary">Details & Fees</Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <Container>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Description</Col>
                <Col className="loan-details-value">{product.Product}</Col>
              </Row>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Product</Col>
                <Col className="loan-details-value">{product.Description}</Col>
              </Row>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Loan Type</Col>
                <Col className="loan-details-value">
                  {state.purpose === "OO" ? "Owner Occupied" : "Investment"}
                </Col>
              </Row>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Rate Type</Col>
                <Col className="loan-details-value">
                  {mapIntType[product.IntType]}{" "}
                  <span className="loan-details-note">
                    {product.IntType === "FIXED"
                      ? product.FixedRateTerm + " years"
                      : ""}{" "}
                  </span>
                </Col>
              </Row>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Repayment Type</Col>
                <Col className="loan-details-value">
                  {REPAYMENT_TYPE_PI_IO.includes(product.RepaymentType)
                    ? "Principal & Interest"
                    : "Interest Only"}
                </Col>
              </Row>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Monthly Repayment</Col>
                <Col className="loan-details-value">
                  ${monthlyRepayment.toLocaleString()}
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <Container>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Redraw</Col>
                <Col className="loan-details-value">
                  {mapAnswer[product.RedrawAvailable]}
                </Col>
              </Row>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Offset Account</Col>
                <Col className="loan-details-value">
                  {mapAnswer[product.FullOffsetAvailable]}
                </Col>
              </Row>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Application Fee</Col>
                <Col className="loan-details-value">{product.EstFee}</Col>
              </Row>
              <Row className="loan-details-property">
                <Col className="loan-details-key">Ancillary Setup Fee</Col>
                <Col className="loan-details-value">
                  {product.AncSetupCosts}
                </Col>
              </Row>
              <Row className="loan-details-property">
                <Col className="loan-details-key">
                  Ongoing Fee &amp; Frequency
                </Col>
                <Col className="loan-details-value">
                  $
                  {product.OngoingFeeFreq === "Monthly"
                    ? product.OngoingFee + " p.m."
                    : product.AnnualFee + " p.a."}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      {state.refinance && rateSavings > 0 && (
        <Container className="loan-details-box">
          <Row>
            <Col className="loan-details-header text-primary">
              Estimated Savings{" "}
              <span className="text-secondary loan-details-header-note">
                (compared against your loan)
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <Container>
                <Row className="loan-details-property">
                  <Col className="loan-details-key">
                    Interest Rate Savings p.a.
                  </Col>
                  <Col className="loan-details-value">{rateSavings}%</Col>
                </Row>
                <Row className="loan-details-property">
                  <Col className="loan-details-key">Repayment Savings p.m.</Col>
                  <Col className="loan-details-value">
                    ${monthlySavings.toLocaleString()}
                  </Col>
                </Row>
                <Row className="loan-details-property-last">
                  <Col className="loan-details-key">Repayment Savings p.a.</Col>
                  <Col className="loan-details-value">
                    ${(monthlySavings * 12).toLocaleString()}
                  </Col>
                </Row>
                <Row className="loan-details-property-last">
                  <Col className="loan-details-key">
                    Repayment Savings your term
                  </Col>
                  <Col className="loan-details-value">
                    $
                    {(
                      monthlySavings *
                      12 *
                      parseInt(state.loanTerm)
                    ).toLocaleString()}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

LoanDetails.protoTypes = {
  product: PropTypes.object.isRequired,
  isInterestOnly: PropTypes.bool.isRequired,
}

export default LoanDetails
