// import data from "../data/LoanProducts.json"

export const REPAYMENT_TYPE_IO = [
  "PI & IO",
  "IO",
  "IO, Int Cap",
  "Int in Adv",
  "Int Cap",
]
export const REPAYMENT_TYPE_PI_IO = ["PI & IO", "PI"]

// export const getValidProducts = () => {
//   const now = new Date()

//   const loandProductDetails = data.NewDataSet.LoanProductDetails ? data.NewDataSet.LoanProductDetails.filter(
//     product =>
//       parseFloat(product.IntRate) > 0 &&
//       product.LenderEnabled === "Y" &&
//       product.ProductEnabled === "Y" &&
//       product.HideDetails === "N" &&
//       new Date(product.StartDT) <= now &&
//       new Date(product.EndDT) >= now
//   ) : []

//   const loanProductSpecials = data.NewDataSet.LoanProductSpecials ? data.NewDataSet.LoanProductSpecials.filter(
//     product =>
//       product.IntRate &&
//       parseFloat(product.IntRate) > 0 &&
//       product.LenderEnabled &&
//       product.LenderEnabled === "Y" &&
//       product.ProductEnabled &&
//       product.ProductEnabled === "Y" &&
//       product.HideDetails &&
//       product.HideDetails === "N" &&
//       new Date(product.StartDT) <= now &&
//       new Date(product.EndDT) >= now
//   ) : []

//   const loanProducts = [...loandProductDetails, ...loanProductSpecials]

//   loanProducts
//     .sort((a, b) => parseFloat(a.IntRate) - parseFloat(b.IntRate))
//     .sort(
//       (c, d) =>
//         parseFloat(c.CompareIntRate) - parseFloat(d.CompareIntRate)
//     )
//     .sort((e, f) => e.IntType.localeCompare(f.IntType))
//     .sort(
//       (g, h) =>
//         parseFloat(g.FixedRateTerm) - parseFloat(h.FixedRateTerm)
//     )

//   return loanProducts
// }

// export const filterProducts = (products, options) => {
//   return products.filter(
//     product =>
//       (options.interestType !== "ALL"
//         ? product.IntType === options.interestType
//         : true) &&
//       (options.purpose === "OO" ? product.PurposeOO === "Y" : true) &&
//       (options.purpose === "INV" ? product.PurposeINV === "Y" : true) &&
//       (options.purpose === "SMSF" ? product.Description === "SMSF" : true) &&
//       (options.loanAmount
//         ? parseFloat(options.loanAmount) >= parseFloat(product.MinLoanAmount)
//         : true) &&
//       (options.interestPaymentType === 'ALL'? true : (options.interestPaymentType === 'IO'
//         ? REPAYMENT_TYPE_IO.includes(product.RepaymentType)
//         : REPAYMENT_TYPE_PI_IO.includes(product.RepaymentType))) &&
//       (parseFloat(options.loanAmount) > 0 && parseFloat(options.propertyValue) > 0
//         ? (parseFloat(options.loanAmount) / parseFloat(options.propertyValue)) * 100 >=
//         parseFloat(product.LVRRangeMin) &&
//         (parseFloat(options.loanAmount) / parseFloat(options.propertyValue)) * 100 <=
//         parseFloat(product.LVRRangeMax)
//         : true)
//   )
// }

export const changeFileExtension = (filename, extension) => {
  return filename.substr(0, filename.lastIndexOf(".")) + `.${extension}`
}

// export const getLenders = () => {
//   return data.NewDataSet.Lenders
// }

// export const getLenderLogos = () => {
//   const lenders = getLenders()
//   let lenderLogos = {}

//   for (let lender of lenders) {
//     lenderLogos[lender.ID] = `${lender.Name}.jpg`
//   }

//   return lenderLogos
// }

export const getLogoSet = logos => {
  let logoSet = {}
  for (let logo of logos.allFile.edges) {
    logoSet[logo.node.name + "." + logo.node.extension] =
      logo.node.childImageSharp.gatsbyImageData
  }
  return logoSet
}

export const calculateRepayment = (
  principal,
  interestRate,
  numberOfPayments,
  isInterestOnly
) => {
  if (isInterestOnly) {
    return calculateIORepayment(principal, interestRate)
  }

  return calculatePIRepayment(principal, interestRate, numberOfPayments)
}

export const calculatePIRepayment = (
  principal,
  interestRate,
  numberOfPayments
) => {
  if (interestRate === 0) {
    return Math.round(principal / numberOfPayments)
  }

  const monthlyInterestRate = interestRate / 100 / 12
  const temp = Math.pow(1 + monthlyInterestRate, numberOfPayments)
  const repayment = (principal * monthlyInterestRate * temp) / (temp - 1)

  return Math.round(repayment)
}

export const calculateIORepayment = (principal, interestRate) => {
  if (interestRate === 0) {
    return 0
  }

  const repayment = (principal * interestRate) / 100 / 12

  return Math.round(repayment)
}

export const numberFormatter = numberString => {
  // Make sure you validate number before using this helper
  const numberMap = numberString.replaceAll(",", "").split("")

  const decimalNumbers =
    numberMap.indexOf(".") !== -1 &&
    numberMap.splice(numberMap.indexOf("."), numberMap.length)

  for (let i = numberMap.length - 3; i > 0; i -= 3) {
    numberMap[i] = `,${numberMap[i]}`
  }

  return `${numberMap.join("")}${decimalNumbers ? decimalNumbers.join("") : ""}`
}

export const isNumberValidForUpdating = numberString => {
  const numericString = numberString.replaceAll(",", "")

  return (
    new RegExp(/^-{0,1}\d*\.{0,1}\d+$/, "gi").test(numericString) ||
    numericString === "" ||
    (numericString.split(".").length - 1 === 1 && numericString.endsWith("."))
  )
}
