import React from "react"
import PropTypes from "prop-types"
import { Card, Container, Row, Col } from "react-bootstrap"
import "./loanComparisonHeader.css"

const LoanComparisonHeader = ({ loanAmount, hasSavings }) => {
  return (
    <Card className="comparison-header-card">
      <Container>
        <Row className="bg-primary comparison-header-loan-amount">
          <Col>
            <div>Loan Amount</div>
            <div className="comparison-header-loan-amounta-value">
              ${parseInt(loanAmount).toLocaleString()}
            </div>
          </Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Description</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Product</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Interest rate p.a.</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Comparison Rate p.a.</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Monthly Repayment</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Rate Type</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Loan Type</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Repayment Type</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Redraw</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Offset Account</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Application Fee</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Ancillary Setup Fee</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Monthly Fee</Col>
        </Row>
        <Row className="comparison-header-row">
          <Col>Annual Fee</Col>
        </Row>
        {hasSavings && (
          <>
            <Row className="comparison-header-row">
              <Col className="comparison-header-savings">
                Savings Off Your Rate p.a.
              </Col>
            </Row>

            <Row className="comparison-header-row">
              <Col className="comparison-header-savings">
                Repayment Savings p.m.
              </Col>
            </Row>

            <Row className="comparison-header-row">
              <Col className="comparison-header-savings">
                Repayment Savings p.a.
              </Col>
            </Row>

            <Row className="comparison-header-row">
              <Col className="comparison-header-savings">
                Repayment Savings your term
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Card>
  )
}

LoanComparisonHeader.propTypes = {
  loanAmount: PropTypes.number.isRequired,
  hasCurrentFee: PropTypes.bool,
}

LoanComparisonHeader.defaultProps = {
  hasSavings: false,
}

export default LoanComparisonHeader
