import React, { useContext, useCallback } from "react"
import { GlobalContext } from "../context/provider.js"
import PropTypes from "prop-types"
import { Container, Row, Col, Button } from "react-bootstrap"
import Plus from "../assets/plus.svg"
import Minus from "../assets/minus.svg";
import PlusDisabled from "../assets/plus-disabled.svg"
import ArrowDown from "../assets/arrow-down.svg"
import ArrowUp from "../assets/arrow-up.svg"
import "./filterAction.css"

const FilterAction = ({ product, status }) => {
  const { state, dispatch } = useContext(GlobalContext)

  const { expanded } = state

  const rowId = product.ID

  const addToCompare = useCallback(
    e => {
      e.stopPropagation()
      dispatch({ type: "ADD_LOAN_COMPARISON", product: product })
    },
    [dispatch, product]
  );

  const removeProductFromComparison = (e) => {
    e.stopPropagation();
    dispatch({ type: "REMOVE_LOAN_COMPARISON", product });
  };


  const toggleDetails = useCallback(
    e => {
      e.stopPropagation()
      if (expanded.includes(rowId)) {
        dispatch({ type: "COLLAPSE_ROW", rowId: rowId })
      } else {
        dispatch({ type: "EXPAND_ROW", rowId: rowId })
      }
    },
    [dispatch, expanded, rowId]
  )

  const openForm = useCallback(
    e => {
      e.stopPropagation()
      dispatch({
        type: "TOGGLE_AFFILIATE_LEAD_FORM_MODAL",
        show: true,
        product: product,
      })
    },
    [dispatch, product]
  )

  return (
    <Container>
      <Row className="filter-action">
        <Col xs={12} md={12} lg={6}>
          <Row className="filter-action-row-add">
            <Col className="filter-action-text">
              {status === "ADDED" && (
                <div
                  className="filter-action-added text-secondary"
                  role="button"
                  tabIndex="0"
                  onClick={removeProductFromComparison}
                  onKeyDown={removeProductFromComparison}
                >
                  Remove <Minus className="filter-action-icon" />
                </div>
              )}
              {status === "PENDING" && (
                <div
                  className="filter-action-none text-primary"
                  role="button"
                  tabIndex="0"
                  onClick={e => addToCompare(e)}
                  onKeyDown={e => addToCompare(e)}
                >
                  Add to compare <Plus className="filter-action-icon" />
                </div>
              )}
              {status === "DISABLED" && (
                <div
                  className="filter-action-disabled"
                  role="button"
                  tabIndex="0"
                  onClick={e => e.stopPropagation()}
                  onKeyDown={e => e.stopPropagation()}
                >
                  Add to compare <PlusDisabled className="filter-action-icon" />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="filter-action-text">
              {!expanded.includes(rowId) && (
                <div
                  className="filter-action-none text-primary"
                  role="button"
                  tabIndex="-1"
                  onClick={e => toggleDetails(e)}
                  onKeyDown={e => toggleDetails(e)}
                >
                  View Details{" "}
                  <ArrowDown className="filter-action-small-icon" />
                </div>
              )}
              {expanded.includes(rowId) && (
                <div
                  className="filter-action-hide"
                  role="button"
                  tabIndex="-1"
                  onClick={e => toggleDetails(e)}
                  onKeyDown={e => toggleDetails(e)}
                >
                  Hide Details <ArrowUp className="filter-action-small-icon" />
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={12} lg={6} className="filter-action-button">
          <Button
            variant="primary"
            className="filter-action-apply"
            onClick={e => openForm(e)}
          >
            Enquire
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

FilterAction.propTypes = {
  product: PropTypes.object.isRequired,
}

export default FilterAction
