import React, { useContext, useCallback } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Card, Container, Row, Col, Button } from "react-bootstrap"
import { GlobalContext } from "../context/provider.js"
import ReactTooltip from "react-tooltip"
import {
  getLogoSet,
  calculateRepayment,
  REPAYMENT_TYPE_PI_IO,
} from "../utils/helpers"
import "./loanComparisonProduct.css"

// const lenderLogos = getLenderLogos()

const mapAnswer = {
  Y: "Yes",
  N: "No",
}

const LoanComparisonProduct = ({ product, hasSavings }) => {
  const { state, dispatch } = useContext(GlobalContext)

  const handleApply = useCallback(() => {
    dispatch({ type: "TOGGLE_LOAN_COMPARISON_MODAL", show: false })
    dispatch({
      type: "TOGGLE_AFFILIATE_LEAD_FORM_MODAL",
      show: true,
      product: product,
    })
  }, [dispatch, product])

  const logos = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpeg|jpg|gif|png)/" }
          relativeDirectory: { eq: "logos" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 300, layout: CONSTRAINED)
            }
            name
            extension
          }
        }
      }
    }
  `)

  const logoSet = getLogoSet(logos)

  const logo = `${product.Lender}.jpg`

  const { loanAmount, interestRate, loanTerm } = state

  const rate = parseFloat(product.IntRate)

  const isInterestOnly = product.RepaymentType === "IO"

  const userMonthlyRepayment = calculateRepayment(
    parseFloat(loanAmount),
    interestRate,
    parseInt(loanTerm) * 12,
    isInterestOnly
  )
  const monthlyRepayment = calculateRepayment(
    parseFloat(loanAmount),
    rate,
    parseInt(loanTerm) * 12,
    isInterestOnly
  )
  const monthlySavings = Math.round(userMonthlyRepayment - monthlyRepayment)
  const rateSavings = Math.round((interestRate - rate) * 100) / 100

  return (
    <div style={{ textAlign: "left" }}>
      <Card className="comparison-product-card">
        <Container>
          <Row className="comparison-product-loan-amount">
            <Col>
              {logoSet[logo] ? (
                <GatsbyImage
                  image={logoSet[logo]}
                  className="filter-lender-logo"
                />
              ) : (
                product.Lender
              )}
            </Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>
              <div data-tip data-for={product.Product}>
                {product.Product}
              </div>
              <ReactTooltip id={product.Product} place="bottom" effect="solid">
                {product.Product}
              </ReactTooltip>
            </Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>{product.Description}</Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>{product.IntRate}%</Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>{product.CompareIntRate}%</Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>
              $
              {calculateRepayment(
                parseFloat(state.loanAmount),
                parseFloat(product.IntRate),
                parseInt(state.loanTerm) * 12,
                isInterestOnly
              ).toLocaleString()}
            </Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>{product.IntType}</Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>
              {state.purpose === "OO" ? "Owner Occupied" : "Investment"}
            </Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>
              {REPAYMENT_TYPE_PI_IO.includes(product.RepaymentType)
                ? "Principal & Interest"
                : "Interest Only"}
            </Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>{mapAnswer[product.RedrawAvailable]}</Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>{mapAnswer[product.FullOffsetAvailable]}</Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>{product.EstFee}</Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>{product.AncSetupCosts}</Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>${product.OngoingFee}</Col>
          </Row>
          <Row className="comparison-product-row">
            <Col>${product.AnnualFee}</Col>
          </Row>
          {hasSavings && (
            <>
              <Row className="comparison-product-row">
                <Col className="comparison-product-savings">
                  {rateSavings > 0 ? rateSavings + "%" : ""}
                </Col>
              </Row>
              <Row className="comparison-product-row">
                <Col className="comparison-product-savings">
                  {monthlySavings > 0
                    ? "$" + monthlySavings.toLocaleString()
                    : ""}
                </Col>
              </Row>
              <Row className="comparison-product-row">
                <Col className="comparison-product-savings">
                  {monthlySavings > 0
                    ? "$" + (12 * monthlySavings).toLocaleString()
                    : ""}
                </Col>
              </Row>
              <Row className="comparison-product-row">
                <Col className="comparison-product-savings">
                  {monthlySavings > 0
                    ? "$" +
                      (
                        parseInt(loanTerm) *
                        12 *
                        monthlySavings
                      ).toLocaleString()
                    : ""}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </Card>
      <Button
        variant="primary"
        className="loan-comparison-product-button"
        onClick={() => handleApply()}
      >
        Enquire
      </Button>
    </div>
  )
}

LoanComparisonProduct.propTypes = {
  product: PropTypes.object,
  isCurrentLoan: PropTypes.bool,
}

LoanComparisonProduct.defaultProps = {
  product: {},
}

export default LoanComparisonProduct
